export const environment = {
  production: true,
  staging: false,
  apiEndpoint: 'https://api.services4eo.com/request',
  authority: 'https://triple-a.services4eo.com',
  clientId: 'f9804100-687f-4b44-93b8-940a01ac1e96',
  scope: 'profile openid email user_name gsc4eo',
  apiKey: 'f285f8a3-d741-4fba-b4a7-93d27d2a6247',
  support: 'https://service4eo.atlassian.net/servicedesk',
  storeUrl: 'https://store.services4eo.com/'
};
