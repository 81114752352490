<div class="mb-4">
  <label class="block text-gray-700 mb-2" [for]="id">
    {{ label }}
  </label>
  <div *ngFor="let key of objectKeys(options)">
    <div *ngIf="options[key].length !== 0; else empty">
      <div class="w-full flex justify-between items-center">
        <div class="w-full flex gap-4 items-center p-2">
          <fa-icon
            class="hover:cursor-pointer transform duration-300 {{
              collapsedClass(isGroupCollapsed[key])
            }}
  "
            [ngClass]="selectedGroupClass(isGroupCollapsed[key])"
            size="lg"
            [icon]="faChevronDown"
            (click)="isGroupCollapsed[key] = !isGroupCollapsed[key]"
          ></fa-icon>

          <h4
            class="font-bold"
            [ngClass]="selectedGroupClass(isGroupCollapsed[key])"
          >
            {{ key }}
          </h4>
        </div>
        <div
          class="text-gray-400 rounded-full bg-gray-200 w-8 text-center px-2 py-0.5 font-bold"
        >
          {{ options[key].length }}
        </div>
      </div>

      <!-- <div class="w-full flex justify-between items-center">
        <div class="w-full flex gap-4 p-2">
          <fa-icon
            (click)="isGroupCollapsed[key] = !isGroupCollapsed[key]"
            [ngClass]="collapsedClass(isGroupCollapsed[key])"
            class="
  text-primary-100
  hover:cursor-pointer
  transform duration-300
  "
            size="lg"
            [icon]="faChevronDown"
          ></fa-icon>

          <h4 class="text-primary-100 font-bold">{{ key }}</h4>
        </div>

        <div>TEST</div>
      </div> -->

      <div *ngIf="isGroupCollapsed[key]">
        <div *ngFor="let option of options[key]; index as idx">
          <div
            class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-gray-100 rounded-md"
          >
            <!-- <input
      type="checkbox"
      class="
      rounded-md
      h-5
      w-5
      hover:rounded-full
      hover:cursor-pointer
      checked:rounded-full
      text-asset-100
      disabled:cursor-not-allowed
      disabled:border-gray-200
    "
      [name]="'checkbox-' + idx"
      [id]="option.id ?? idx"
      [value]="option.value"
      [disabled]="option.disabled"
      (click)="onClick($event)"
      *ngIf="option.selectable"
    /> -->
            <!-- <ng-template #notSelectable>
      <div
        class="flex h-5 w-5 justify-center items-center"
        *ngIf="option.playable"
      >
        <fa-icon
          [title]="'actions.play' | translate"
          [ngClass]="disableIconClass(option.id)"
          class=" text-asset-100"
          [icon]="faPlay"
          (click)="onIconClick(option)"
        ></fa-icon>
      </div>
    </ng-template> -->

            <div class="w-full flex justify-between items-center pl-4">
              <div class="w-full flex gap-4 items-center">
                <fa-icon
                  *ngxPermissionsOnly="['customer']"
                  [ngClass]="collapsedClass(isServiceCollapsed[key + idx])"
                  class="
        text-asset-100
        hover:cursor-pointer
        transform duration-300
        "
                  size="lg"
                  [icon]="faChevronDown"
                  (click)="onCollapseClick(option, key + idx)"
                ></fa-icon>

                <div
                  class="relative inline-block"
                  [ngClass]="selectedLabelClass(option.id, key + idx)"
                >
                  <span
                    *ngIf="option.orderCount > 0"
                    class="absolute top-0 left-0 inline-block w-4 h-4 text-center text-white transform -translate-x-full -translate-y-1/2 bg-primary-400 rounded-full"
                  >{{option.orderCount}}</span>
                  <p>
                    {{ option.label }}
                  </p>
                </div>
              </div>

              <app-icon-button
                *ngIf="option.playable"
                iconClass="px-0 py-0"
                [icon]="faPlay"
                [isDisabled]="isDisabled(option.id)"
                (clicked)="onIconClick(option)"
              >
              </app-icon-button>
            </div>
            <!-- <div
      class="w-full ml-5 flex justify-between items-center"
      *ngIf="option.collapsible; else notCollapsible"
    >
      <p [ngClass]="selectedLabelClass(option.id, idx)">
        {{ option.label }}
      </p>
      <fa-icon
        *ngIf="option.collapsible"
        [ngClass]="collasedClass(idx)"
        class="
        text-asset-100
        hover:cursor-pointer
        transform duration-300
      "
        size="lg"
        [icon]="faChevronDown"
        (click)="onCollapseClick(option, idx)"
      ></fa-icon>
    </div> -->
            <!-- <ng-template #notCollapsible>
      <div
        class="w-full ml-5 flex justify-between items-center"
        *ngIf="option.downloadable; else notDownloadable"
      >
        <p class="{{ option.selectable ? 'mr-5' : 'mx-5' }}">
          {{ option.label }}
        </p>
        <a [href]="option.sampleURI" [download]="option.sampleURI" target="_blank">
          <fa-icon
            [title]="'actions.download' | translate"
            [ngClass]="disableIconClass(option.id)"
            class=" text-asset-100"
            [icon]="faDownload"
            (click)="onDownloadClick(option)"
          ></fa-icon>
        </a>
      </div>
      <ng-template #notDownloadable>
        <p class="mx-5">
          {{ option.label }}
        </p>
      </ng-template>
    </ng-template> -->
          </div>
          <div
            class="p-4 transform duration-300 ease-out"
            *ngIf="isServiceCollapsed[key + idx]"
            [@slideInOut]
          >
            <!-- <div
      class="p-2 flex justify-between items-center"
      *ngFor="let uri of option.sampleURI; index as uriIdx"
    >
      <div class="flex gap-4 items-center">
        <input
          type="checkbox"
          class="
            rounded-md
            h-5
            w-5
            hover:rounded-full
            hover:cursor-pointer
            checked:rounded-full
            text-asset-100
            disabled:cursor-not-allowed
            disabled:border-gray-200
          "
          [name]="'checkbox-' + option.id + uriIdx"
          [id]="option.id + uriIdx"
          [value]="uri"
          [checked]="option.checked.includes(option.id + uriIdx)"
          [disabled]="option.disabled"
          (click)="onClick($event)"
          *ngIf="option.selectable"
        />
        <p>
          {{ option.label }}
          <span
            class="text-xs font-bold text-primary-100"
            *ngIf="option.sampleURI?.length > 1"
          >
            {{ uriIdx + 1 }}
          </span>
        </p>
      </div>
      <a
        class="ml-5"
        [href]="uri"
        [download]="uri"
        target="_blank"
        *ngIf="option.downloadable"
      >
        <fa-icon
          [tooltip]="'actions.download' | translate"
          class=" text-asset-100"
          [icon]="faDownload"
          (click)="onDownloadClick(option)"
        ></fa-icon>
      </a>
    </div> -->
            <div
              *ngIf="option.orders.length !== 0"
              class="flex pl-2 items-center gap-4"
            >
              <p class="text-primary-100 font-bold uppercase whitespace-nowrap">
                {{ "main-page.orders.orders" | translate }}
              </p>
              <hr class="my-3 border w-full" />
            </div>

            <div *ngIf="!isLoading[key + idx]; else loading">
              <div *ngIf="option.orders.length > 0; else noOrders">
                <div *ngFor="let order of option.orders" class="py-2">
                  <app-accordion
                    (details)="onOrderDetails(order)"
                    [title]="
                      ('main-page.sidebar.order-made-on' | translate) +
                      ' ' +
                      serializeDateToDateTime(order.createdAt, currentLanguage)
                    "
                    [label]="
                      ('main-page.sidebar.order-no' | translate) + '' + order.id
                    "
                    [secondLabel]="
                      serializeDateToDateTime(order.createdAt, currentLanguage)
                    "
                  >
                    <div *ngIf="!isLoadingOuputs.get(order.id); else loading" class="flex flex-col gap-4 text-sm">
                      <div class="flex justify-between items-center mb-4">
                        <p class="text-asset-100">
                          {{
                            "main-page.orders.order-details.status" | translate
                          }}
                        </p>
                        <div class="flex gap-2 items-center">
                          <div class="flex relative h-3 w-3">
                            <span
                              [ngClass]="statusAnimationClass(order.status)"
                              class="absolute inline-flex h-full w-full rounded-full opacity-75"
                            ></span>
                            <span
                              [ngClass]="statusClass(order.status)"
                              class="inline-flex rounded-full h-3 w-3"
                            ></span>
                          </div>
                          {{ order.status }}
                        </div>
                      </div>
                      <div
                        class="flex justify-between"
                        *ngFor="let detail of orderDetails"
                      >
                      <ng-container *ngIf="order[detail.acessor]">
                        <p class="text-asset-100">
                          {{ detail.label | translate }}
                        </p>

                        <p class="break-all">
                          {{
                            detail.fn
                              ? detail.fn(order[detail.acessor])
                              : order[detail.acessor]
                          }}
                        </p>
                      </ng-container>
                  </div>
                  <!-- <div
                    class="flex flex-col gap-2"
                    *ngIf="
                      order.dataDisseminationOutput;
                      else orderDownloadable
                    "
                  >
                    <p class="text-asset-100 font-bold">
                      {{ "main-page.orders.order-details.layers" | translate }}
                    </p>
                    <div
                      class="flex justify-between items-center"
                      *ngFor="let layer of order.dataDisseminationOutput.layers"
                    >
                      <div class="flex gap-4 items-center">
                        <input
                          type="checkbox"
                          class="
                            rounded-md
                            h-5
                            w-5
                            hover:rounded-full
                            hover:cursor-pointer
                            checked:rounded-full
                            text-asset-100
                            disabled:cursor-not-allowed
                          disabled:border-gray-200
                          "
                          [name]="
                            'checkbox-' + order.dataDisseminationOutput.orderID
                          "
                          [id]="order.dataDisseminationOutput.orderID"
                          [value]="order.dataDisseminationOutput.uri"
                          (click)="onOrderLayerClick($event, order.serviceID)"
                        />
                        <p>
                          {{ layer }}
                        </p>
                      </div>
                      <a
                      class="ml-5"
                      [href]="order.dataDisseminationOutput.uri"
                      [download]="order.dataDisseminationOutput.uri"
                      target="_blank"
                    >
                      <fa-icon
                        [tooltip]="'actions.download' | translate"
                        class=" text-asset-100"
                        [icon]="faDownload"
                        (click)="onDownloadClick(option)"
                      ></fa-icon>
                    </a>
                    </div>
                  </div> -->
                  <!-- <ng-template #orderDownloadable> -->
                  <!-- <div
                    class="flex flex-col gap-2"
                    *ngIf="order.files.length !== 0; else orderJobs"
                  >
                    <p class="text-asset-100 font-bold">
                      {{
                        "main-page.orders.order-details.downloads" | translate
                      }}
                    </p>
                    <div
                      class="flex justify-between items-center"
                      *ngFor="let file of order.files; index as orderIdx"
                    >
                      <p>
                        {{ order.serviceID }}
                        <span class="text-xs font-bold text-primary-100">
                          {{ orderIdx + 1 }}
                        </span>
                      </p>
                      <a
                        class="ml-5"
                        [href]="file.file"
                        [download]="file.file"
                        target="_blank"
                      >
                        <app-icon-button
                          [tooltip]="'actions.download' | translate"
                          [icon]="faDownload"
                          (clicked)="onDownloadClick(option)"
                        >
                        </app-icon-button>
                      </a>
                    </div>
                  </div> -->
                  <!-- </ng-template> -->
                  <div
                    *ngIf="(order.status !== 'FINISHED' && order.status !== 'SUBSCRIPTION_ACTIVE'); else orderJobs"
                  >
                    <p *ngIf="order.status === 'CREATED'" class="text-primary text-xs">{{ "main-page.orders.still-processing" | translate }}</p>
                    <div *ngIf="order.status === 'PAYMENT_PENDING'" class="flex flex-col gap-4 justify-start text-primary-300">
                      <p>{{ "main-page.orders.payment-pending.message" | translate }}</p>
                      <div class="pl-4 flex flex-col gap-2">
                        <p>1. {{ "main-page.orders.payment-pending.instructions.step1.normal" | translate }};</p>
                        <p>2. {{ "main-page.orders.payment-pending.instructions.step2.normal" | translate }};</p>
                        <p>3. {{ "main-page.orders.payment-pending.instructions.step3.normal" | translate }} 
                          <b>{{ "main-page.orders.payment-pending.instructions.step3.bold" | translate }} {{ order.id }}</b>;
                        </p>
                        <p>4. {{ "main-page.orders.payment-pending.instructions.step4.normal" | translate }};</p>
                        <p>5. {{ "main-page.orders.payment-pending.instructions.step5.normal" | translate }}.</p>
                      </div>
                      <p><a [href]="env.storeUrl" target="_blank"><b><u>{{ "main-page.orders.payment-pending.link" | translate }}</u></b></a></p>
                    </div>
                  </div>
                  <ng-template #orderJobs>
                    <p class="text-asset font-bold">
                      {{ "main-page.orders.order-details.layers" | translate }}
                    </p>
                    <div
                      *ngFor="let job of order.jobs; index as jobIdx"
                      class="flex flex-col gap-4 text-xs"
                    >
                      <div
                        *ngFor="let output of job.outputs; index as orderIdx"
                        class="flex flex-col gap-4 w-full"
                      >
                        <!-- <div class="flex justify-between items-center">
                          <p
                            class="text-xs font-bold text-primary-100 break-all"
                          >
                            {{ output.productID }}
                          </p>
                          <a
                            *ngIf="output.downloadURI"
                            class="ml-5"
                            [href]="output.downloadURI"
                            [download]="output.downloadURI"
                            target="_blank"
                          >
                            <app-icon-button
                              [tooltip]="'actions.download' | translate"
                              [icon]="faDownload"
                            >
                            </app-icon-button>
                          </a>
                        </div> -->
                        <div
                          *ngFor="
                            let layerKey of objectKeys(output.layers);
                            index as layerIdx
                          "
                          class="flex flex-col gap-4"
                        >
                          <div
                            *ngIf="isTimeSeries(output.type); else noTimeSeries"
                            class="flex justify-between items-center w-full"
                          >
                            <div class="flex gap-4 items-center">
                              <input
                                type="checkbox"
                                class="
                              rounded-md
                              h-5
                              w-5
                              hover:rounded-full
                              hover:cursor-pointer
                              checked:rounded-full
                              text-asset
                              disabled:cursor-not-allowed
                            disabled:border-gray-200
                            "
                                [name]="'checkbox-' + layerIdx"
                                [id]="output.jobID + layerIdx"
                                (click)="
                                  onClick(
                                    $event,
                                    layerKey,
                                    output.layers,
                                    isDataTimeSeries(output.type),
                                    order.timestamps,
                                    order.serviceSubtype,
                                    order.id,
                                    orderIdx,
                                    layerIdx,
                                    jobIdx
                                  )
                                "
                                [checked]="isChecked(layerKey, order.id, orderIdx, layerIdx, jobIdx)"
                              />
                              <p
                                class="text-xs font-bold text-primary break-all w-full"
                              >
                                {{ layerKey }}
                              </p>
                            </div>
                            <div class="flex justify-end">
                              <app-icon-button
                              [isDisabled]="!isChecked(layerKey, order.id, orderIdx, layerIdx, jobIdx)"
                              iconClass="text-blue-200 hover:bg-gray-200"
                              [icon]="faArrowTurnUp"
                              (clicked)="
                                onTopClick(layerKey, order.id, orderIdx, layerIdx, jobIdx)
                              "
                              size="sm"
                            >
                            </app-icon-button>
                            <app-icon-button
                              [isDisabled]="!isChecked(layerKey, order.id, orderIdx, layerIdx, jobIdx)"
                              iconClass="text-blue-200 hover:bg-gray-200"
                              [icon]="faArrowsToCircle"
                              (clicked)="
                                onZoomClicked(output.layers[layerKey][0])
                              "
                              size="sm"
                            >
                            </app-icon-button>
                            <app-icon-button
                              [isDisabled]="!isChecked(layerKey, order.id, orderIdx, layerIdx, jobIdx)"
                              iconClass="text-blue-200 hover:bg-gray-200"
                              [icon]="faRectangleList"
                              (clicked)="
                                onLegendClicked(layerKey, order.id, orderIdx, layerIdx, jobIdx)
                              "
                              size="sm"
                            >
                            </app-icon-button>
                            <app-icon-button
                              [isDisabled]="!isChecked(layerKey, order.id, orderIdx, layerIdx, jobIdx)"
                              iconClass="text-blue-200 hover:bg-gray-200"
                              [icon]="faMagicWandSparkles"
                              (clicked)="
                                onStyleClicked(output.layers[layerKey], layerKey, order.id, orderIdx, layerIdx, jobIdx)
                              "
                              size="sm"
                            >
                            </app-icon-button>
                            </div>
                            
                            <!-- <app-icon-button
                              [isDisabled]="true"
                              iconClass="text-gray-400"
                              [icon]="faInfoCircle"
                              (clicked)="
                                onLayerInfoClick(output.layers[layerKey])
                              "
                              size="sm"
                            >
                            </app-icon-button> -->
                          </div>
                          <ng-template #noTimeSeries>
                            <div class="flex justify-between w-full">
                              <p
                                class="text-xs font-bold text-primary break-all"
                              >
                                {{ layerKey }}
                              </p>
                              <p
                                (click)="
                                  isOutputCollapsed[
                                    layerIdx
                                  ] = !isOutputCollapsed[layerIdx]
                                "
                                class="hover:underline hover:cursor-pointer"
                                [ngClass]="
                                  outputLabelClass(isOutputCollapsed[layerIdx])
                                "
                              >
                                {{
                                  isOutputCollapsed[layerIdx]
                                    ? ("actions.hide.downloads" | translate)
                                    : ("actions.show.downloads" | translate)
                                }}
                              </p>
                            </div>
                          </ng-template>
                          <div
                            class="flex flex-col gap-4"
                            *ngIf="
                              output.type === 'downloadable' &&
                              isOutputCollapsed[layerIdx]
                            "
                          >
                            <div
                              *ngFor="let layer of output.layers[layerKey]"
                              class="flex justify-between items-center"
                            >
                              <p>{{ layer.title }}</p>

                                  <a
                                    *ngIf="layer.downloadURI"
                                    class="ml-5"
                                    [href]="layer.downloadURI"
                                    [download]="layer.downloadURI"
                                    target="_blank"
                                  >
                                    <app-icon-button
                                      [icon]="faDownload"
                                    >
                                    </app-icon-button>
                                  </a>
                                </div>
                              </div>

                              <!-- <div
                        *ngFor="let layer of output.layers[layerKey]"
                        class="flex justify-between items-center w-full"
                      >
                        <div class="flex gap-4 items-center w-full">
                          <input
                            type="checkbox"
                            class="
                          rounded-md
                          h-5
                          w-5
                          hover:rounded-full
                          hover:cursor-pointer
                          checked:rounded-full
                          text-asset-100
                          disabled:cursor-not-allowed
                        disabled:border-gray-200
                        "
                            [name]="'checkbox-' + layer.title"
                            [id]="output.jobID + layer.title + layerIdx"
                            [value]="layer.geoServerURI"
                            (click)="
                              onOrderLayerClick(
                                $event,
                                order,
                                layerKey,
                                layer.wmsParams
                              )
                            "
                          />
                          <div
                            class="flex justify-between items-center w-full"
                          >
                            <div class="flex items-center gap-1">
                              <p class="break-all">
                                {{ layer.title }}
                              </p>
                              <app-icon-button
                                iconClass="text-gray-400"
                                [icon]="faInfoCircle"
                                (clicked)="onLayerInfoClick(layer)"
                                size="sm"
                              >
                              </app-icon-button>
                            </div>
                            <a
                              *ngIf="layer.downloadURI"
                              class="ml-5"
                              [href]="layer.downloadURI"
                              [download]="layer.downloadURI"
                              target="_blank"
                            >
                              <app-icon-button
                                [tooltip]="'actions.download' | translate"
                                [icon]="faDownload"
                              >
                              </app-icon-button>
                            </a>
                          </div>
                        </div>
                      </div> -->
                              <!-- <div *ngFor="let layer of layers.value">
                        {{ layers.value }}
                      </div> -->

                              <!-- <div class="flex gap-4 items-center w-full">
                        <input
                          *ngIf="layer.geoServerLayer"
                          type="checkbox"
                          class="
                        rounded-md
                        h-5
                        w-5
                        hover:rounded-full
                        hover:cursor-pointer
                        checked:rounded-full
                        text-asset-100
                        disabled:cursor-not-allowed
                      disabled:border-gray-200
                      "
                          [name]="'checkbox-' + layer.title"
                          [id]="output.jobID + layer.title + layerIdx"
                          [value]="layer.geoServerURI"
                          (click)="
                            onOrderLayerClick(
                              $event,
                              order,
                              layer.geoServerLayer
                            )
                          "
                        />
                        <div
                          class="flex justify-between items-center w-full"
                        >
                          <div class="flex items-center gap-1">
                            <p class="break-all">
                              {{ layer.title }}
                            </p>
                            <app-icon-button
                              iconClass="text-gray-400"
                              [icon]="faInfoCircle"
                              (clicked)="onLayerInfoClick(layer)"
                              size="sm"
                            >
                            </app-icon-button>
                          </div>
                          <a
                            *ngIf="layer.downloadURI"
                            class="ml-5"
                            [href]="layer.downloadURI"
                            [download]="layer.downloadURI"
                            target="_blank"
                          >
                            <app-icon-button
                              [tooltip]="'actions.download' | translate"
                              [icon]="faDownload"
                            >
                            </app-icon-button>
                          </a>
                        </div>
                      </div> -->
                            </div>
                          </div>

                          <!-- <p>
                  {{ order.serviceID }}
                  <span class="text-xs font-bold text-primary-100">
                    {{ orderIdx + 1 }}
                  </span>
                </p>
                <a
                  class="ml-5"
                  [href]="file.file"
                  [download]="file.file"
                  target="_blank"
                >
                  <app-icon-button
                    [tooltip]="'actions.download' | translate"
                    [icon]="faDownload"
                    (clicked)="onDownloadClick(option)"
                  >
                  </app-icon-button>
                </a> -->
                        </div>
                      </ng-template>
                      <!-- <p class="flex justify-between">
            <span class="text-asset-100">{{
              "main-page.orders.order-details.id" | translate
            }}</span
            >{{ order.id }}
          </p>
          <p class="flex justify-between">
            <span class="text-asset-100">{{
              "main-page.orders.order-details.identifier" | translate
            }}</span
            >{{ order.serviceID }}
          </p>
          <p class="flex justify-between items-center">
            <span class="text-asset-100">{{
              "main-page.orders.order-details.status" | translate
            }}</span>

            <span
              class="rounded-md p-1 flex bg-primary-100 bg-opacity-50 text-white"
              >{{ order.status }}</span
            >
          </p>
          <p class="flex justify-between">
            <span class="text-asset-100">{{
              "main-page.orders.order-details.created-at" | translate
            }}</span
            >{{ serializeToLocaleDate(order.created, currentLanguage) }}
          </p>
          <p class="flex justify-between">
            <span class="text-asset-100">{{
              "main-page.orders.order-details.begin" | translate
            }}</span
            >{{ serializeToLocaleDate(order.begin, currentLanguage) }}
          </p>
          <p class="flex justify-between">
            <span class="text-asset-100">{{
              "main-page.orders.order-details.end" | translate
            }}</span
            >{{ serializeToLocaleDate(order.end, currentLanguage) }}
          </p> -->
                    </div>
                    <!-- <div *ngIf="ordersByServiceId[order.serviceID]; else loading"> -->
                    <!-- {{ ordersByServiceId[order.serviceID]?.identifier }}
        </div>
        <ng-template #loading>
          <spinner variant="linear"></spinner>
        </ng-template> -->
                  </app-accordion>
                  <!-- <p
        class="p-2"
        [title]="
          ('main-page.sidebar.order-made-on' | translate) +
          ' ' +
          serializeDateToDateTime(order.created, currentLanguage)
        "
      >
        <span class="font-bold text-asset-100"
          >{{ "main-page.sidebar.order-no" | translate }}{{ order.id }}
        </span>
        -
        <span>{{
          serializeDateToDateTime(order.created, currentLanguage)
        }}</span>
      </p> -->
                </div>
              </div>
              <ng-template #noOrders>
                <p class="text-gray-400">
                  {{ "main-page.orders.helper" | translate }}
                </p>
              </ng-template>
            </div>
            <ng-template #loading>
              <spinner variant="linear"></spinner>
              <!-- <p class="text-gray-400">
        {{ "main-page.orders.helper" | translate }}
      </p> -->
            </ng-template>
            <!-- <div
    class="p-4 transform duration-300 ease-out"
    *ngIf="isCollapsed[idx]"
    [@slideInOut]
    >
    <div *ngFor="let order of option.orders" class="pl-5">
      <p
        class="p-2"
        [title]="
          ('main-page.sidebar.order-made-on' | translate) +
          ' ' +
          serializeDateToDateTime(order.created, currentLanguage)
        "
      >
        <span class="font-bold text-asset-100"
        >{{ "main-page.sidebar.order-no" | translate }}{{ order.id }}
        </span>
        -
        <span>{{
          serializeDateToDateTime(order.created, currentLanguage)
          }}</span>
      </p>
    </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="flex justify-between w-full items-center py-2">
        <h4 class="text-primary-100 font-bold">{{ key }}</h4>
        <fa-icon
          *ngxPermissionsOnly="['customer']"
          class="
text-primary-100
hover:cursor-pointer
transform duration-300
"
          size="lg"
          [icon]="faChevronDown"
        ></fa-icon>
      </div> -->

    <ng-template #empty>{{
      "main-page.filters.not-found" | translate
    }}</ng-template>
  </div>
</div>
