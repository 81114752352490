<div class="flex bg-white justify-between items-center p-1 rounded-t-lg opacity-90">
  <div class="flex gap-4 items-center">
    <fa-icon
      class="
        px-3
        py-2
        hover:bg-gray-100
        text-asset
        rounded-full
        cursor-pointer
      "
      (click)="zoomOut()"
      [icon]="faSearchMinus"
    ></fa-icon>
    <fa-icon
      class="
        px-3
        py-2
        hover:bg-gray-100
        text-asset
        rounded-full
        cursor-pointer
      "
      (click)="zoomIn()"
      [icon]="faSearchPlus"
    ></fa-icon>
    <fa-icon
      *ngIf="isLayerActive && !isPlotDrawingActive && isTimeSeries"
      class="
        px-3
        py-2
        hover:bg-gray-100
        text-asset
        rounded-full
        cursor-pointer
      "
      (click)="onPlotClicked()"
      [icon]="faChartLine"
    ></fa-icon>
    <fa-icon
      *ngIf="isLayerActive && isPlotDrawingActive && isTimeSeries"
      class="
        px-3
        py-2
        bg-gray-200
        text-asset
        rounded-full
        cursor-pointer
      "
      (click)="onPlotClicked()"
      [icon]="faChartLine"
    ></fa-icon>
    <fa-icon
      *ngIf="isLayerActive && !isAnimationActive && isTimeSeries && !animationLoading"
      class="
        px-3
        py-2
        hover:bg-gray-100
        text-asset
        rounded-full
        cursor-pointer
      "
      (click)="onAnimationClicked()"
      [icon]="faCirclePlay"
    ></fa-icon>
    <fa-icon
      *ngIf="isLayerActive && isAnimationActive && isTimeSeries && !animationLoading"
      class="
        px-3
        py-2
        hover:bg-gray-100
        text-asset
        rounded-full
        cursor-pointer
      "
      (click)="onAnimationStopClicked()"
      [icon]="faStop"
    ></fa-icon>
    <fa-icon
      *ngIf="isLayerActive && isTimeSeries && animationLoading"
      class="
        px-3
        py-2
        hover:bg-gray-100
        text-asset
        rounded-full
        cursor-pointer
        fa-fade
      "
      [icon]="faSpinner"
    ></fa-icon>
    
    <p class="text-asset-500">{{ timelineLabel }}</p>
    <br>
    <br>
    <app-button
      (click)="toggleMultipleSelection()"
      [label]="isMultiSelectOn ? ('main-page.timeline.buttons.clearselect' | translate) : ('main-page.timeline.buttons.multiselect' | translate)"
      [leftIcon]="isMultiSelectOn ? faTrashCan : faArrowPointer"
    ></app-button>
    <p *ngIf="isMultiSelectOn" 
        class="text-asset"
     >{{ numSelected }} {{ 'main-page.timeline.buttons.numSelected' | translate }}</p>
  </div>

  <a
    [href]="currentUri"
    [download]="currentUri"
    target="_blank"
    [ngClass]="noCurrentUriClass()"
  ><fa-icon
    class="
      px-3
      py-2
      hover:bg-gray-100
      text-asset
      rounded-full
      cursor-pointer
    "
    [icon]="faFileDownload"
    [ngClass]="noCurrentUriClass()"
  ></fa-icon></a>
</div>
<div [id]="visualizationId" class="bg-asset bg-opacity-20 shadow-lg"></div>

<app-animation-dialog
  *ngIf="animationDateSelect"
  (timeInterval)="onTimeIntervalSelect($event)"
  (cancel)="onCancelAnimation()"
></app-animation-dialog>

<app-series-chart #chart
  (dateChange)="onSerieschartDateChange($event)"
  (cancel)="onSerieschartClose()"
></app-series-chart>

<app-layer-legend>
  
</app-layer-legend>