import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MapService } from '@core';
import { TimelineService } from '@core/services/timeline/timeline.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  animations: [
    trigger('timelineAppear', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class HomePageComponent implements OnInit {
  activeMenu?: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private timelineService: TimelineService,
    private mapService: MapService
  ) {}

  test = [1, 2, 3, 4, 5];

  objectKeys = Object.keys;

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      data => (this.activeMenu = data.menu)
    );
  }

  get layers() {
    return this.timelineService.layersState;
  }

  hiddenClass(hidden: boolean) {
    return { hidden };
  }

  isOnTop(layerKey: string) {
    return this.timelineService.topLayer? this.timelineService.topLayer.includes(layerKey) : false;
  }

  isTimelineOnTop(layerKey: string){
    return this.mapService.topTimeline? this.mapService.topTimeline.includes(layerKey) : false;
  }

  get hasTopLayer() {
    return this.timelineService.topLayer? true : false;
  }

  get hasTopTimeline(){
    return this.mapService.topTimeline;
  }

  reduceOpacity(index: number, layerKey: string) {
    if (this.objectKeys(this.layers).length === 1) {
      return false;
    }
    else {
      if (this.hasTopLayer || this.hasTopTimeline) {
        if (this.hasTopTimeline){
          return !this.isTimelineOnTop(layerKey);
        }
        else{
          return false;
        } 
      } else {
        return !(index === this.objectKeys(this.layers).length -1);
      }
    }
  }
}
